import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StoreProvider } from 'easy-peasy'
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles'
import AppStore from '@v4/store/appStore'
import PageData from '@v4/utils/data/PageData'
import ErrorBoundary from '@v4/components/common/ErrorBoundary'
import builderStore from './store/builderStore'

import Routes from './components/routes'
import AppRouter from './components/common/AppRouter'
import I18nextProvider from './components/common/I18nextProvider'

const generateClassName = createGenerateClassName({
	productionPrefix: 'score-app-',
})

const App = ({ type, initialScroll }) => {
	useEffect(() => {
		if (initialScroll) {
			window.scrollTo(0, initialScroll)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<StoreProvider store={window.builderStore || builderStore}>
			<AppStore.Provider>
				<I18nextProvider>
					<AppRouter>
						<StylesProvider generateClassName={generateClassName}>
							<ErrorBoundary>
								<div id="top" style={{ visibility: 'hidden' }} />
								<Routes type={type} />
								<div id="bottom" style={{ visibility: 'hidden' }} />
							</ErrorBoundary>
						</StylesProvider>
					</AppRouter>
				</I18nextProvider>
			</AppStore.Provider>
		</StoreProvider>
	)
}

App.propTypes = {
	type: PropTypes.oneOf(Object.values(PageData.TYPES)),
	initialScroll: PropTypes.number,
}

App.defaultProps = {
	initialScroll: undefined,
}

export default memo(App)
